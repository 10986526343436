exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-fleet-bus-tsx": () => import("./../../../src/pages/fleet-bus.tsx" /* webpackChunkName: "component---src-pages-fleet-bus-tsx" */),
  "component---src-pages-fleet-hds-tsx": () => import("./../../../src/pages/fleet-hds.tsx" /* webpackChunkName: "component---src-pages-fleet-hds-tsx" */),
  "component---src-pages-fleet-platform-tsx": () => import("./../../../src/pages/fleet-platform.tsx" /* webpackChunkName: "component---src-pages-fleet-platform-tsx" */),
  "component---src-pages-fleet-solo-tsx": () => import("./../../../src/pages/fleet-solo.tsx" /* webpackChunkName: "component---src-pages-fleet-solo-tsx" */),
  "component---src-pages-fleet-tilt-tsx": () => import("./../../../src/pages/fleet-tilt.tsx" /* webpackChunkName: "component---src-pages-fleet-tilt-tsx" */),
  "component---src-pages-fleet-trolley-tsx": () => import("./../../../src/pages/fleet-trolley.tsx" /* webpackChunkName: "component---src-pages-fleet-trolley-tsx" */),
  "component---src-pages-fleet-walking-floor-tsx": () => import("./../../../src/pages/fleet-walking-floor.tsx" /* webpackChunkName: "component---src-pages-fleet-walking-floor-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

